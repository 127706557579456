import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputField from "../components/ui/form-elements/InputField";
import TextField from "../components/ui/form-elements/TextField";
import SubmitButton from "./../components/ui/form-elements/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectField from "../components/ui/form-elements/SelectField";
import axios from "./../util/axios";
import { toast } from "react-toastify";
import PhoneField from "../components/ui/form-elements/PhoneField";
import Gender from "../components/ui/form-elements/Gender";
import NameField from "../components/ui/form-elements/NameField";
import FileUploadField from "../components/ui/form-elements/FileUploadField";

const InstructorApplyForJob = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.authedUser?.user?.id);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [stepName, setStepName] = useState("closer_shot");

  const wizardTabs = [
    { step: "closer_shot", label: t("applyForJob.closerShot") },
    { step: "technicalskills", label: t("applyForJob.technicalskills") },
    { step: "experience", label: t("applyForJob.experience") },
    {
      step: "availability",
      label: t("applyForJob.availability")
    },
    {
      step: "job_requirements",
      label: t("applyForJob.jobrequirements")
    },
    { step: "personal", label: t("applyForJob.attachments") }
  ];
  const generateWizardTab = (step, label, index) => (
    
    <div
      
      onClick={() => handleTabClick(step)}
      className={`wizard_tab ${stepName === step ? "active" : ""}`}
      key={index}
    >
      <div className="num">
        <span>{index + 1}</span>
      </div>
      <div className="content">
        <h6>{label}</h6>
      </div>
    </div>
    
  );
  const handleTabClick = (nextStep) => {
    const currentIndex = wizardTabs.findIndex(tab => tab.step === stepName);
    const nextIndex = wizardTabs.findIndex(tab => tab.step === nextStep);

    // Check if the target step is next or previous
    if (nextIndex === currentIndex + 1) {
      // If moving forward, validate current step
      let isValid = true;
      switch (stepName) {
        case "closer_shot":
          isValid = validateCloserShot();
          break;
        case "technicalskills":
          isValid = validateTechnicalSkills();
          break;
        case "experience":
          isValid = validateExperience();
          break;
        case "availability":
          isValid = validateAvailability();
          break;
        case "job_requirements":
          isValid = validateJobRequirements();
          break;
        default:
          isValid = true;
      }

      // Only switch to the next step if current step is valid
      if (isValid) {
        setStepName(nextStep);
      }
    } else if (nextIndex < currentIndex) {
      // If moving backward, switch directly
      setStepName(nextStep);
    }
  };
  const [formData, setFormData] = useState({
    job_id: +id,
    user_id: userId,
    name_ar: "",
    name_en: "",
    firstname: "",
    lastname: "",
    phone_number: "",
    whatsapp_number: "",
    governrate :"Cairo",
    gender: "male",
    email: "",
    licensed: false,
    available_hours: "",
    available_from6amto12pm: "",
    available_from12pmto6pm: "",
    available_from6pmto12am: "",
    night_shift: false,
    age: "",
    subjectsyoucanteach:[],
    quran: false,
    laptop: false,
    emergencyMobileDataPlan: false,
    Internet_and_Connection: "",
    ageteaching: [],
    whyyou:"",
    years_teaching: "",
    license: "",
    personal_image: "",
  });
  
  const validateFields = (requiredFields) => {
    for (const [field, message] of Object.entries(requiredFields)) {
      if (!formData[field] || formData[field].trim() === '') {
        toast.error(message);
        return false;
      }
    }
    return true;
  };
  
 

  const validateCloserShot = () => {
    const requiredFields = {
      firstname: t("applyForJob.nameRequired"),
      lastname: t("applyForJob.nameRequired"),
      age: t("applyForJob.ageRequired"),
      governrate: t("applyForJob.governrateRequired"),
      email: t("applyForJob.emailRequired"),
      gender: t("applyForJob.genderRequired"),
      phone_number: t("applyForJob.phoneRequired"),
      whatsapp_number: t("applyForJob.whatsappRequired")
    };
     // Special validation for email format
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     if (formData.email && !emailRegex.test(formData.email)) {
       toast.error(t("applyForJob.invalidEmail"));
       return false;
     }

     // Special validation for age
     if (formData.age && (formData.age < 18 || formData.age > 100)) {
       toast.error(t("applyForJob.invalidAge"));
       return false;
     }

     // Special validation for phone numbers
    
  
     
     return validateFields(requiredFields);

  };

  const validateTechnicalSkills = () => {
    if (formData.subjectsyoucanteach.length === 0) {
      toast.error(t("applyForJob.pleaseSelectSubjects"));
      return false;
    }
    return true;
  };

  const validateExperience = () => {
    if (!formData.whyyou || !formData.years_teaching || formData.ageteaching.length === 0) {
      toast.error(t("applyForJob.pleaseCompleteAllFields"));
      return false;
    }
    return true;
  };

  const validateAvailability = () => {
    if (!formData.available_hours || !formData.available_from6amto12pm || 
        !formData.available_from12pmto6pm || !formData.available_from6pmto12am) {
      toast.error(t("applyForJob.pleaseCompleteAllFields"));
      return false;
    }
    return true;
  };

  const validateJobRequirements = () => {
    if (!formData.Internet_and_Connection) {
      toast.error(t("applyForJob.pleaseCompleteAllFields"));
      return false;
    }
    return true;
  };

  const validatePersonal = () => {
    if (!formData.name_ar || !formData.name_en || !formData.cv || 
        !formData.license || !formData.personal_image || !formData.introduction_video) {
      toast.error(t("applyForJob.pleaseCompleteAllFields"));
      return false;
    }
    return true;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      for (const key in formData) {
        if (formData[key] === "") {
          toast.error(t("applyForJob.incompleteDataError"));
          return;
        }
      }
      const headers = {
        Accept: "application/json",
        "content-type": "multipart/form-data"
      };
      const reqOptions = {
        method: "POST",
        headers: headers,
        data: formData
      };
      const response = await axios.request(
        `/hr/Create_instructor/`,
        reqOptions
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(t("applyForJob.applicationSentSuccessfully"));
        navigate("/jobs");
      } else {
        toast.error(response?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = (currentStep, nextStep) => {
    let isValid = false;
    switch (currentStep) {
      case "closer_shot":
        isValid = validateCloserShot();
        break;
      case "technicalskills":
        isValid = validateTechnicalSkills();
        break;
      case "experience":
        isValid = validateExperience();
        break;
      case "availability":
        isValid = validateAvailability();
        break;
      case "job_requirements":
        isValid = validateJobRequirements();
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      setStepName(nextStep);
    }
  };

  return (
    <section className="apply-for-job">
      <div className="container">
        <div className="row m-0 justify-content-center">
          <div className="col-12 p-2">
            <h3>{t("joinOurTeam")}</h3>
          </div>
          <div className="col-12 p-2">
            <form className="form-ui" onSubmit={handleSubmit}>
              <div className="mb-4 pb-3 wizard_tabs">
                {wizardTabs.map((tab, index) =>
                  generateWizardTab(tab.step, tab.label, index)
                )}
              </div>
              {stepName === "closer_shot" && (
                <>
                  <div className="form_group">
                    {/* name */}
                    <NameField setFormData={setFormData} formData={formData} />
                    <InputField
                      label={t("applyForJob.age")}
                      placeholder={"30"}
                      type="number"
                      htmlFor="age"
                      id={"age"}
                      value={formData.age}
                      formData={formData}
                      setFormData={setFormData}
                      required
                    />
                    <SelectField
                      label={t("applyForJob.governrate")}
                      id="governrate"
                      value={formData.governrate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          governrate: e.target.value
                        })
                      }
                      options={[
                        { name: t("applyForJob.cairo"), value: "Cairo" },
                        { name: t("applyForJob.alexandria"), value: "Alexandria" },
                        { name: t("applyForJob.giza"), value: "Giza" },
                        { name: t("applyForJob.portsaid"), value: "Port Said" },
                        { name: t("applyForJob.suez"), value: "Suez" },
                        { name: t("applyForJob.luxor"), value: "Luxor" },
                        { name: t("applyForJob.aswan"), value: "Aswan" },
                        { name: t("applyForJob.asyut"), value: "Asyut" },
                        { name: t("applyForJob.beheira"), value: "Beheira" },
                        { name: t("applyForJob.benisuef"), value: "Beni Suef" },
                        { name: t("applyForJob.dakahlia"), value: "Dakahlia" },
                        { name: t("applyForJob.damietta"), value: "Damietta" },
                        { name: t("applyForJob.faiyum"), value: "Faiyum" },
                        { name: t("applyForJob.gharbia"), value: "Gharbia" },
                        { name: t("applyForJob.ismailia"), value: "Ismailia" },
                        { name: t("applyForJob.kafrelsheikh"), value: "Kafr El Sheikh" },
                        { name: t("applyForJob.matruh"), value: "Matruh" },
                        { name: t("applyForJob.minya"), value: "Minya" },
                        { name: t("applyForJob.monufia"), value: "Monufia" },
                        { name: t("applyForJob.newvalley"), value: "New Valley" },
                        { name: t("applyForJob.northsinai"), value: "North Sinai" },
                        { name: t("applyForJob.qalyubia"), value: "Qalyubia" },
                        { name: t("applyForJob.qena"), value: "Qena" },
                        { name: t("applyForJob.redsea"), value: "Red Sea" },
                        { name: t("applyForJob.sharqia"), value: "Sharqia" },
                        { name: t("applyForJob.sohag"), value: "Sohag" },
                        { name: t("applyForJob.southsinai"), value: "South Sinai" }
                      ]}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <InputField
                      label={t("auth.email")}
                      placeholder={t("auth.emailPlaceHolder")}
                      htmlFor="email"
                      value={formData.email}
                      formData={formData}
                      id={"email"}
                      setFormData={setFormData}
                      required
                    />
                    <Gender
                      setFormData={setFormData}
                      formData={formData}
                      dataKey="gender"
                      required
                    />
                  </div>
                  <div className="form_group">
                    <PhoneField
                      label={t("auth.phone")}
                      formData={formData}
                      setFormData={setFormData}
                      value={formData.phone_number}
                      id="phone_number"
                      required
                    />
                    <PhoneField
                      label={t("auth.whatsapp")}
                      formData={formData}
                      setFormData={setFormData}
                      value={formData.whatsapp_number}
                      id="whatsapp_number"
                      required
                    />
                  </div>
                  <div className="buttons justify-content-end">
                    <button
                      className="next"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextStep("closer_shot", "technicalskills");
                      }}
                    >
                      {t("applyForJob.next")}
                    </button>
                  </div>
                </>
              )}
              {stepName === "technicalskills" && (
                <>
                  <div className="form_group">
                    
                    
                    <div className="d-flex-column" style={{width:"100%"}}>
                    <label>{t("applyForJob.doyoumemorizequran")}</label>
                      {[ 
                        { name: t("applyForJob.yes"), value: true }, 
                        { name: t("applyForJob.no"), value: false } 
                      ].map(option => (
                        <div
                        className="option-item" 
                        style={{ 
                          display: "flex", 
                          gap: "12px",
                          marginRight:"20px",
                          marginLeft:"20px",
                          width: "100%",
                        }} key={option.value}>
                          <input
                            type="radio"
                            id={`option-${option.value}`} // Unique ID for each radio button
                            name="memorizeQuran" // Group name for radio buttons
                            value={option.value}
                            checked={formData.quran === option.value} // Set checked based on the form state
                            onChange={() => {
                              setFormData({
                                ...formData,
                                quran: option.value // Set the selected value (true or false)
                              });
                            }}
                          />
                          <label htmlFor={`option-${option.value}`}>{option.name}</label>
                        </div>
                      ))}
                    </div>
                    
                    <div className="d-flex-column"  style={{width:"100%"}}>
                    <label>{t("applyForJob.subjectsyoucanteach")}</label>
                      {[
                        
                        { name: t("applyForJob.tajweed"), value: "tajweed" },
                        { name: t("applyForJob.tafseer"), value: "tafseer" },
                        { name: t("applyForJob.contemplation"), value: "contemplation" },
                        { name: t("applyForJob.fiqeh"), value: "fiqeh" },
                        { name: t("applyForJob.hadeeth"), value: "hadeeth" },
                        { name: t("applyForJob.purpose"), value: "purpose" },

                      ].map(option => (
                        <div className="option-item flex-row-reverse justify-content-end" 
                        style={{ 
                          display: "flex", 
                          gap: "12px",
                          marginRight:"20px",
                          marginLeft:"20px",
                          width: "100%",
                        }} key={option.value}>
                          <label htmlFor={option.value}>{option.name}</label>
                          <input
                            type="checkbox"
                            id="subjectsyoucanteach"
                            name="subjectsyoucanteach"
                            checked={formData.subjectsyoucanteach.includes(option.value)}
                            onChange={(e) => {
                              const value = option.value;
                              setFormData({
                                ...formData,
                                subjectsyoucanteach: e.target.checked
                                  ? [...formData.subjectsyoucanteach, value] // Add value if checked
                                  : formData.subjectsyoucanteach.filter(item => item !== value) // Remove value if unchecked
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    
                  </div>
                  
                  
                  
                  <div className="d-flex align-items-center gap-2 flex-row-reverse justify-content-end">
                    <label htmlFor="license">{t("applyForJob.licensed")}</label>
                    <input
                      type="checkbox"
                      id="license"
                      name="license"
                      checked={formData.licensed}
                      onChange={(e) =>
                        setFormData({ ...formData, licensed: e.target.checked })
                      }
                    />
                  </div>
                  
                  <div className="buttons">
                    <button
                      className="back"
                      onClick={(e) => {
                        e.preventDefault();
                        setStepName("closer_shot");
                      }}
                    >
                      {t("applyForJob.previous")}
                    </button>
                    <button
                      className="next"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextStep("technicalskills", "experience");
                      }}
                    >
                      {t("applyForJob.next")}
                    </button>
                  </div>
                </>
              )}
              {stepName === "experience" && (
                <>
                  <div className="form_group">
                    
                    <TextField
                      label={t("applyForJob.whyYou")}
                      id="whyyou"
                      htmlFor="whyyou"
                      value={formData.whyyou}
                      formData={formData}
                      setFormData={setFormData}
                    />
                    
                  </div>
                  <div className="form_group">
                    
                    
                    <InputField
                      label={t("applyForJob.yearsTeaching")}
                      placeholder="00"
                      type="number"
                      id="years_teaching"
                      htmlFor="years_teaching"
                      value={formData.years_teaching}
                      formData={formData}
                      setFormData={setFormData}
                    />
                    
                    <div className="d-flex-column gap-3" style={{width:"100%"}}>
                    <label>{t("applyForJob.ageteaching")}</label>
                      {[
                        { name: t("applyForJob.lessthan10"), value: "lessthan10" },
                        { name: t("applyForJob.from10to18"), value: "from10to18" },
                        { name: t("applyForJob.from18to25"), value: "from18to25" },
                        { name: t("applyForJob.from25to40"), value: "from25to40" },
                        { name: t("applyForJob.above40"), value: "above40" }
                      ].map(option => (
                        <div className="option-item flex-row-reverse justify-content-end" 
                        style={{ 
                          display: "flex", 
                          gap: "12px",
                          marginRight:"20px",
                          marginLeft:"20px",
                          width: "100%",
                        }} key={option.value}>
                          <label htmlFor={option.value}>{option.name}</label>
                          <input
                            type="checkbox"
                            id="ageteaching"
                            name="ageteaching"
                            checked={formData.ageteaching.includes(option.value)}
                            onChange={(e) => {
                              const value = option.value;
                              setFormData({
                                ...formData,
                                ageteaching: e.target.checked
                                  ? [...formData.ageteaching, value] // Add value if checked
                                  : formData.ageteaching.filter(item => item !== value) // Remove value if unchecked
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                   
                  </div>
                 
                  <div className="buttons">
                    <button
                      className="back"
                      onClick={(e) => {
                        e.preventDefault();
                        setStepName("technicalskills");
                      }}
                    >
                      {t("applyForJob.previous")}
                    </button>
                    <button
                      className="next"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextStep("experience", "availability");
                      }}
                    >
                      {t("applyForJob.next")}
                    </button>
                  </div>
                </>
              )}
              {stepName === "availability" && (
                <>
                  <div className="form_group">
                    <InputField
                      label={t("applyForJob.availableHours")}
                      type="number"
                      placeholder={"4"}
                      id="available_hours"
                      htmlFor="available_hours"
                      value={formData.available_hours}
                      formData={formData}
                      setFormData={setFormData}
                    />
                    <div className="d-flex align-items-center gap-2 flex-row-reverse justify-content-end" style={{ width: '100%' }} >
                      <label htmlFor="night_shift">{t("applyForJob.nightshift")}</label>
                      <input
                        type="checkbox"
                        id="night_shift"
                        name="night_shift"
                        checked={formData.night_shift}
                        onChange={(e) =>
                          setFormData({ ...formData, night_shift: e.target.checked })
                        }
                      />
                    </div>
                    
                  </div>
                  <div className="form_group">
                    <InputField
                      label={t("applyForJob.availablefrom6amto12pm")}
                      type="number"
                      placeholder={"4"}
                      id="available_from6amto12pm"
                      htmlFor="available_from6amto12pm"
                      value={formData.available_from6amto12pm}
                      formData={formData}
                      setFormData={setFormData}
                    />
                    <InputField
                      label={t("applyForJob.availablefrom12pmto6pm")}
                      type="number"
                      placeholder={"4"}
                      id="available_from12pmto6pm"
                      htmlFor="available_from12pmto6pm"
                      value={formData.available_from12pmto6pm}
                      formData={formData}
                      setFormData={setFormData}
                    />
                     <InputField
                      label={t("applyForJob.availablefrom6pmto12am")}
                      type="number"
                      placeholder={"4"}
                      id="available_from6pmto12am"
                      htmlFor="available_from6pmto12am"
                      value={formData.available_from6pmto12am}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </div>
                  
                  <div className="buttons">
                    <button
                      className="back"
                      onClick={(e) => {
                        e.preventDefault();
                        setStepName("experience");
                      }}
                    >
                      {t("applyForJob.previous")}
                    </button>
                    <button
                      className="next"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextStep("availability", "job_requirements");
                      }}
                    >
                      {t("applyForJob.next")}
                    </button>
                  </div>
                </>
              )}
              {stepName === "job_requirements" && (
                <>
                  <div className="form_group">
                    
                    <label>{t("applyForJob.laptop")}</label>
                    <div className="d-flex gap-3">
                      {[ 
                        { name: t("applyForJob.yes"), value: true }, 
                        { name: t("applyForJob.no"), value: false } 
                      ].map(option => (
                        <div className="d-flex align-items-center gap-2 justify-content-end" key={option.value}>
                          <input
                            type="radio"
                            id={`option-${option.value}`} // Unique ID for each radio button
                            name="laptopoption" // Group name for radio buttons
                            value={option.value}
                            checked={formData.laptop === option.value} // Set checked based on the form state
                            onChange={() => {
                              setFormData({
                                ...formData,
                                laptop: option.value // Set the selected value (true or false)
                              });
                            }}
                          />
                          <label htmlFor={`option-${option.value}`}>{option.name}</label>
                        </div>
                      ))}
                    </div>

                    
                  </div>
                  <div className="form_group" >
                    
                    
                    <div className="d-flex-column"  style={{width:"100%"}} >
                      <label>{t("applyForJob.internetandconnection")}</label>
                      {[
                        { name: t("applyForJob.excellentconnection"), value: "excellent" },
                        { name: t("applyForJob.goodconnection"), value: "good" },
                        { name: t("applyForJob.averageconnection"), value: "average" },
                        { name: t("applyForJob.poorconnection"), value: "poor" },
                      ].map((option) => (
                        <div 
                          className="option-item" 
                          style={{ 
                            display: "flex", 
                            gap: "12px",
                            marginRight:"20px",
                            marginLeft:"20px",
                            width: "100%",
                          }}
                          key={option.value}
                          
                        >
                          <input
                            type="radio"
                            id={`option-${option.value}`}
                            name="internetandconnectionoptions"
                            value={option.value}
                            checked={formData.Internet_and_Connection === option.value}
                            onChange={() => setFormData({
                              ...formData,
                              Internet_and_Connection: option.value,
                            })}
                            
                          />
                          <label htmlFor={`option-${option.value}`}> {option.name}</label>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex-column " style={{width:"100%"}}>
                    <label>{t("applyForJob.emergencyMobileDataPlan")}</label>

                      {[ 
                        { name: t("applyForJob.yes"), value: true }, 
                        { name: t("applyForJob.no"), value: false } 
                      ].map(option => (
                        <div 
                        className="option-item" 
                        style={{ 
                          display: "flex", 
                          gap: "12px",
                          marginRight:"20px",
                          marginLeft:"20px",
                          width: "100%",
                        }}
                        >
                          <input
                            type="radio"
                            id={`option-${option.value}`} // Unique ID for each radio button
                            name="emergencyMobileDataPlanoption" // Group name for radio buttons
                            value={option.value}
                            checked={formData.emergencyMobileDataPlan === option.value} // Set checked based on the form state
                            onChange={() => {
                              setFormData({
                                ...formData,
                                emergencyMobileDataPlan: option.value // Set the selected value (true or false)
                              });
                            }}
                          />
                          <label htmlFor={`option-${option.value}`}>{option.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  
                  <div className="buttons">
                    <button
                      className="back"
                      onClick={(e) => {
                        e.preventDefault();
                        setStepName("availability");
                        
                      }}
                    >
                      {t("applyForJob.previous")}
                    </button>
                    <button
                      className="next"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextStep("job_requirements", "personal");
                      }}
                    >
                      {t("applyForJob.next")}
                    </button>
                  </div>
                </>
              )}
              {stepName === "personal" && (
                <>
                  <div className="form_group">
                    <InputField
                      label={t("applyForJob.nameAr")}
                      placeholder={t("dashboard.writeHere")}
                      htmlFor="name_ar"
                      value={formData.name_ar}
                      formData={formData}
                      id={"name_ar"}
                      setFormData={setFormData}
                    />
                    <InputField
                      label={t("applyForJob.nameEn")}
                      placeholder={t("dashboard.writeHere")}
                      htmlFor="name_en"
                      value={formData.name_en}
                      formData={formData}
                      id={"name_en"}
                      setFormData={setFormData}
                    />
                  </div>
                  <div className="form_group">
                    
                    <FileUploadField
                      label={t("applyForJob.license")}
                      htmlFor={"license"}
                      formData={formData}
                      setFormData={setFormData}
                      Accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      uploadText={t("applyForJob.uploadLicense")}
                    />
                    <FileUploadField
                      label={t("applyForJob.personalImage")}
                      htmlFor={"personal_image"}
                      formData={formData}
                      setFormData={setFormData}
                      Accept="image/*"
                      uploadText={t("applyForJob.uploadImage")}
                    />
                  </div>
                 
                  <div className="buttons">
                    <button
                      className="back"
                      onClick={(e) => {
                        e.preventDefault();
                        setStepName("job_requirements");
                      }}
                    >
                      {t("applyForJob.previous")}
                    </button>
                    <SubmitButton
                      name={t("jobsPage.submit")}
                      loading={loading}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstructorApplyForJob;
