import React, { useEffect, useState } from "react";
import axios from "./../../util/axios";
import DataLoader from "./../ui/DataLoader";
import Slider from "./Slider";

const BestAudios = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "/learningcenter/list_sound_files/?list_files=true&highlighted=true"
        );
        if (response.status === 200 || response.status === 201) {
          setFiles(response?.data?.message || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFiles();
  }, []);

  const handleReacting = async (id, react) => {
    try {
      const res = await axios.post(
        "/learningcenter/Add_like_or_dislike/audio/",
        {
          item_id: id,
          react: react
        }
      );
      if (res.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === id
              ? {
                  ...file,
                  likes: res.data.object.likes,
                  dislikes: res.data.object.dislikes,
                  user_reaction: res.data.object.user_reaction
                }
              : file
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <DataLoader minHeight="300px" />
      ) : (
        <div className="row m-0">
          <div className="col-12 p-2">
            <div className="swiper_pagination_title">
              {/* Optionally render list title or other list information */}
            </div>
          </div>
          <div className="col-12 p-2 pt-3">
            <Slider
              onReacting={handleReacting}
              slides={files}
              prevbuttonClass=".swiper-button-prev"
              nextbuttonClass=".swiper-button-next"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BestAudios;
